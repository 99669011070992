import React, {useContext} from "react";

import {Secondary, A11y} from "@churchofjesuschrist/eden-buttons";
import Stack from "@churchofjesuschrist/eden-stack";
import {spacing16, accentColorLink} from "@churchofjesuschrist/eden-style-constants";
import { Link } from "react-router-dom";
import styled from "styled-components";

import stringHasValue from "../../../utils/string-has-value";
import useTranslation from "../../../utils/use-translations";
import LoadingSpinner from "../../generic/loading-spinner/loading-spinner";
import signedOnUserContext from "../master-layout/signed-on-user-context";

const stringGroups = [
  {
    name:"shared",
    keys:[
      "guest",
      "signIn",
      "signOut",
      "impersonate",
      "stopImpersonating",
      "myDashboard",
      "findProgram"
    ]
  }
];
const ImpersonationButton = styled(A11y)`
color:${accentColorLink};
font-size:1rem;
`;

const Button = styled(Secondary)`
margin-top:${spacing16};
`;

const defaultObj = {};
const defaultFunc = () => {};
const UpserProfileMenu = ({close = defaultFunc, flagService}) => {
  const {
    user = defaultObj,
    impersonatingUser = defaultObj,
    userLoaded,
    onChangeUser: startImpersonate = defaultFunc,
    onClearImpersonate: stopImpersonate = defaultFunc,
    signOut = defaultFunc,
    signInFromMenuLink = defaultFunc } = useContext(signedOnUserContext);
  const {managedStrings} = useTranslation(stringGroups);

  const {canImpersonate = false} = user;
  let canManageProgram = user.canManageProgram
  const impersonating = stringHasValue(impersonatingUser?.name);
  if (impersonating) {
    canManageProgram = user.canManageProgram;
  }

  const loggedIn = stringHasValue(user?.name);


  let userName = impersonating ? impersonatingUser.name : managedStrings.guest;
  if (loggedIn) {
    userName = user.name;
  }
  /*Note: the routes ensure that profile requires sign in for us,
    so that is essentially another way to log a student in if it is a guest*/
  return userLoaded ? (<Stack>
    {loggedIn ? <><div><Link onClick={close} to="/student/profile" replace={true}>
      {userName}
    </Link></div>
    <Link onClick={close} to="/student/dashboard" replace={true}>
      {managedStrings.myDashboard}
    </Link></> : <span>{userName}</span>}
    {loggedIn && !impersonating
      && canImpersonate && <ImpersonationButton onClick={startImpersonate}>
      {managedStrings.impersonate}
    </ImpersonationButton>}
    {impersonating && <div>
      <ImpersonationButton onClick={stopImpersonate}>
        {managedStrings.stopImpersonating}
      </ImpersonationButton>
    </div>}
    {canManageProgram &&
      <Link onClick={close} to="/pgmadmin/search" replace={true}>
        {managedStrings.findProgram}
      </Link>}

    <Button onClick={() => {
      if (loggedIn) {
        signOut();
      } else {
        signInFromMenuLink();
      }
      close();
    }}>
      {loggedIn ? managedStrings.signOut : managedStrings.signIn}
    </Button>

  </Stack>) : <LoadingSpinner/>
}

export default UpserProfileMenu;