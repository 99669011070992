import fetchWrapper from "../../../utils/fetchWrapper";

const sessionKey = "impersonateData";
const storage = window.sessionStorage;

const userService = () => {
  const loadUser = async () => {
    try {
      const user = await fetchWrapper({url:"userInfo"});
      return user ?? undefined;
    } catch (e) {
      return undefined;
    }
  }
  const checkUser = async (username) => {
    try {
      let url = `checkUser?username=${encodeURIComponent(username)}`;
      const user = await fetchWrapper({url});
      return user;
    } catch (e) {
      return undefined;
    }
  }

  const setImpersonatingUser = (user, reload = false) => {
    if (!user) {
      storage.removeItem(sessionKey);
    } else {
      storage.setItem(sessionKey, JSON.stringify(user));
    }
  }

  return {
    loadUser,
    checkUser,
    setImpersonatingUser
  }
}

export const getImpersonatingUser = () => {
  try {
    let json = storage.getItem(sessionKey);
    return JSON.parse(json);
  }
  catch {
    return undefined;
  }
}

export default userService;