import React from "react";

import Stack from "@churchofjesuschrist/eden-stack";
import { Link } from "react-router-dom";

import useTranslation from "../../../utils/use-translations";

const stringGroups = [
  {
    name: "shared",
    keys: ["systemAlerts", "releaseNotes"]
  }
];

const AdminMenu = () => {
  const { managedStrings } = useTranslation(stringGroups);

  return (
    <Stack>
      <Link to="/admin">{managedStrings.systemAlerts}</Link>
      <Link to="/releasenotes">{managedStrings.releaseNotes}</Link>
    </Stack>
  )
}

export default AdminMenu;