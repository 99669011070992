import fetchWrapper from "./fetchWrapper";

const isExpired = (data, expiration) => {
  const miliseconds = expirationToMiliseconds(expiration);
  if (miliseconds <= 0) {
    return false;
  }

  const savedTime = data.timestamp;
  const nowTime = new Date().getTime();
  return savedTime + miliseconds < nowTime;
}
const expirationToMiliseconds = expiration => {
  if (!expiration) {
    return -1;
  }
  const {minutes = -1,hours = -1,days = -1} = expiration;
  if (days > 0) {
    return days * 24 * 60 * 60 * 1000;

  } else if (hours > 0) {
    return hours * 60 * 60 * 1000;
  } else {
    return minutes * 60 * 1000;
  }
}


const storageBackedLoad = async ({ storage, url, key, expiration }) => {
  let json = storage.getItem(key);
  if (json === "loading") {
    let interval = null;
    return new Promise((resolve, reject) => {
      let checks = 0;
      interval = setInterval(() => {
        json = storage.getItem(key);
        checks++;
        try {
          if (json !== "loading" && json !== null && json !== undefined) {
            const waitedData = JSON.parse(json);
            clearInterval(interval);
            resolve(waitedData);
          }
          if (checks > 20) {
            clearInterval(interval);
            storage.removeItem(key);
            reject(new Error());
          }
        } catch {
          clearInterval(interval);
          storage.removeItem(key);
          reject(new Error());
        }
      }, 100);
    });
  } else if (json !== null && json !== undefined) {
    const localData = JSON.parse(json);
    if (isExpired(localData)) {
      storage.removeItem(key);
      return localStorageBackedLoad({ url, key, expiration });
    }
    return Promise.resolve(localData.item);
  } else {
    storage.setItem(key, "loading");
    try {
      const loadedData = await fetchWrapper( {url} );
      const timestamp = new Date().getTime();
      const storageItem = { timestamp,item:loadedData };
      const storageJson = JSON.stringify(storageItem);
      storage.setItem(key, storageJson);
      return loadedData;
    } catch (e) {
      storage.removeItem(key);
      throw e;
    }
  }
};

const sessionBackedLoad = async({url,key,expiration}) => {
  return storageBackedLoad({
    storage:window.sessionStorage,
    url,key,expiration
  });
}
const localStorageBackedLoad = async({url,key,expiration}) => {
  let storage = null;
  try {
    storage = window.localStorage;
  } catch {
    storage = window.sessionStorage;
  }
  return storageBackedLoad({
    storage,url,key,expiration
  });
}
const clearStorageItem = key => {
  window.localStorage.removeItem(key);
  window.sessionStorage.removeItem(key);
}


export {localStorageBackedLoad, sessionBackedLoad, clearStorageItem}
