import cloneDeep from "lodash.clonedeep";

const manageStringsHelper = (paramStrings = {}, groups = [], verboseMissingStrings = undefined) => {
  if (!groups || groups?.length < 1) {
    return {};
  }
  const strings = cloneDeep(paramStrings);
  let managedStrings = {};
  groups.forEach(({ name = "", keys = [] }) => {
    const fallback = {};
    if (!strings[name]) {
      strings[name] = {};
    }
    //src should be targeted to just the keys we are asking for so we can keep from running into
    //name colisions when multiple sources have the same string key and we ask for it on just one of them
    const src = {};
    keys.forEach((k) => {
      fallback[k] = verboseMissingStrings ? `!${name}.${k}!` : "...";
      const value = strings[name]?.[k];
      if (value) {
        src[k] = value;
      }
    });



    managedStrings = { ...managedStrings, ...fallback, ...src };
  });
  return managedStrings;
};

export default manageStringsHelper;
