import {getImpersonatingUser} from "../components/global-layout/master-layout/user-service";


const fetchWrapper = async ({ url, fetchOptions: options }) => {

  let fetchOptions = options;
  const impersonating = getImpersonatingUser();
  const id = parseInt(impersonating?.impersonatedAccountId);
  if (!isNaN(id)) {
    if (!fetchOptions) {
      fetchOptions = {};
    }
    if (!fetchOptions.headers) {
      fetchOptions.headers = {};
    }
    fetchOptions.credentials = "include";
    //it would be nice to get this from configuration
    //but it isn't critical, and I don't want to make every fetchwrapper and post wrapper call etc need
    //an additional parameter.  The configuration may be put in session storage if we really wanted to do this.
    fetchOptions.headers["acting-as"] = id;
  }
  let response;
  let json = null;
  try {
    response = await fetch(url, fetchOptions);

    const { status } = response;
    if (response.ok) {
      json = response.json().catch((e) => {
        console.log(
          `json parse on a success failed from ${url}. Usually this is an empty ok response and not a problem \n ${e}`
        );
      });

      window.dispatchEvent(new Event("renew"));

    } else {
      throw new Error(`Response not OK, requesting:${url} returned status: ${status}`);
    }
  } catch (err) {
    // 403 Forbidden = not authorized
    // Other errors that can not be handled by attempting relogin.
    //We want the fetch wrapper to throw this so that these failed responses
    //reflect as failures that the UI can handle rather than looking like a false success

    console.error(err); // eslint-disable-line no-console

    throw err;
  }
  return json;
};

const fetchAsBlob = async({url}) => {
  let fetchOptions = {};
  const impersonating = getImpersonatingUser();
  const id = parseInt(impersonating?.impersonatedAccountId);
  if (!isNaN(id)) {
    if (!fetchOptions) {
      fetchOptions = {};
    }
    if (!fetchOptions.headers) {
      fetchOptions.headers = {};
    }
    fetchOptions.credentials = "include";
    //it would be nice to get this from configuration
    //but it isn't critical, and I don't want to make every fetchwrapper and post wrapper call etc need
    //an additional parameter.  The configuration may be put in session storage if we really wanted to do this.
    fetchOptions.headers["acting-as"] = id;
  }

  const results =  await fetch(url, fetchOptions);
  const blob = await results.blob();
  return blob;

}

const postWrapper = async ({ url, data }) => {

  const fetchOptions = {
    method: "POST",
    body: data ? JSON.stringify(data) : null,
    headers: {
      'Content-Type': 'application/json'
    },
  }

  return await fetchWrapper({ url, fetchOptions })

}

const putWrapper = async ({ url, data }) => {

  const fetchOptions = {
    method: "PUT",
    body: data ? JSON.stringify(data) : null,
    headers: {
      'Content-Type': 'application/json'
    },
  }

  return await fetchWrapper({ url, fetchOptions })

}

const deleteWrapper = async ({ url }) => {

  const fetchOptions = {
    method: "DELETE",
    headers: {
      'Content-Type': 'application/json'
    },
  }

  return await fetchWrapper({ url, fetchOptions })

}

export { postWrapper, putWrapper, deleteWrapper, fetchAsBlob }

export default fetchWrapper;
